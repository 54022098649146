/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, NOK){
	const a = 'is-active';
	const v = 'is-visible';

	// 読み込み時のメインビジュアルアニメーション
	const mainVis = $('.idx-mainVis');

	const mainSlider = () => {
		$('.idx-mainVis-slider').slick({
			infinite: true,
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 2500,
			slidesToShow: 1,
			arrows: false,
			dots: false,
		});
	};

	mainVis.addClass(a);
	window.onload = function(){
		if($('.idx-mainVis-slider').length) {
			if($(window).width() > 1024) {
				mainSlider();
			}
		}
	};



})(window.jQuery, window.NOK);
